import withRoot from "../utils/withRoot";
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/styles/withStyles";
import { fade } from "@material-ui/core/styles";
import SearchBox from "../components/SearchBox";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../components/Logo";
import SEO from "../components/SEO";
import Page from "../components/Page";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import qs from "qs";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  content: {
    height: 600,
  },
  pos: {
    marginBottom: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class Pingce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: 1,
      content: "",
      submitted: false,
      form: {},
      inputDisable: false,
      status: "invalid",
      open_backdrop: true,
      wx: null,

      // audio related
      audio: {
        result: {
          data: {
            read_sentence: {
              rec_paper: {
                read_chapter: {},
              },
            },
          },
        },
      },
    };
  }

  getwxConfig = url => {
    let that = this
    import("weixin-js-sdk").then(wx => {
      fetch("https://kids-abc.cn/msg/jstickt?url=" + url)
        .then(resp => {
          if (resp.ok) {
            return resp.json();
          } else {
            console.log(resp.status);
            return {};
          }
        })
        .then(resp => {
          resp["jsApiList"] = [
            "startRecord",
            "stopRecord",
            "onRecordEnd",
            "playVoice",
            "pauseVoice",
            "stopVoice",
            "uploadVoice",
            "downloadVoice",
          ];
          resp["appId"] = resp["app_id"];
          resp["nonceStr"] = resp["nonce_str"];
          console.log(resp);
          wx.config(resp);
          wx.ready(() => {
            console.log("config success");
            that.wx = wx
            this.setState({
              status: "configed",
              open_backdrop: false,
            });
          });
          wx.error(res => {
            console.log("config fail", res);
          });
        });
    });
  };

  componentDidMount = () => {
    let url = "https://kids-abc.cn/pingce/";

    this.getwxConfig(url);

    const { location } = this.props;
    console.log(location);
    let params = qs.parse(location.search);

    if (params["?code"] !== undefined) {
      let code = params["?code"];
      console.log(code);
      fetch("https://kids-abc.cn/msg/openid?code=" + code)
        .then(resp => resp.json())
        .then(resp => {
          console.log(resp);
          this.setState({
            form: resp,
          });
        })
        .catch(e => {
          console.log(e);
          console.log("获取access token失败");
        });
    } else {
      this.setState({
        age: 1,
        content: "nice to meet you",
        inputDisable: false,
      });
    }
  };

  sendPingce = form => {
    let { audio } = this.state;
    console.log(">>> send pingce", form);
    fetch("https://kids-abc.cn/msg/pingce", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(form),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then(resp => {
        if (resp.ok) {
          console.log(resp.status, resp.statusText);
          return resp.json();
        } else {
          return Promise.reject({ error: "请求失败" });
        }
      })
      .then(resp => {
        console.log(resp);
        audio["status"] = "send_success";
        audio["result"] = resp;
        this.setState({
          audio: audio,
          open_backdrop: false,
        });
      })
      .catch(error => {
        audio["status"] = "send_error";

        this.setState({
          audio: audio,
          open_backdrop: false,
        });
        console.log(error);
        console.log("发送失败!");
      });
  };

  handlePingce = () => {
    let that = this;
    console.log("handle pingce");
    let { audio, form, content } = that.state;
    form["localId"] = audio.localId;
    // first: we need to upload the voice
    this.wx.uploadVoice({
      localId: form.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
      isShowProgressTips: 1, // 默认为1，显示进度提示
      success: function (res) {
        console.log("upload success")
        var serverId = res.serverId; // 返回音频的服务器端ID
        form["serverId"] = serverId;
        form["word"] = content;
        audio["status"] = "upload_success";
        that.setState({
          audio: audio,
        });
        that.sendPingce(form);
      },
      fail: function (res) {
        console.log("upload voice fail");
        audio["status"] = "upload_fail";
        that.setState({
          audio: audio,
          open_backdrop: false,
        });
      },
    });

    console.log(form);
  };

  handleStopRecord = () => {
    var that = this;
    let { audio } = that.state;
    console.log("stop record");
    this.wx.stopRecord({
      success: function (res) {
        console.log("stop record success",res);
        let localId = res.localId;
        audio["status"] = "stop_record";
        that.setState({
          audio: { ...audio, localId },
          open_backdrop: false,
          recording: false,
        });
        that.handlePingce();
      },
      fail: function (res) {
        console.log("stop record fail", res);
        audio["status"] = "stop_record_fail";
        that.setState({
          audio: audio,
        });
      },
    });
  };

  handleStartRecord = () => {
    var that = this;
    console.log("start to record");
    let { audio } = that.state;

    this.wx.startRecord({
      success: function (res) {
        console.log("start record success", res);
        audio["status"] = "recording";
        that.setState({ audio: audio });
      },
      fail: function (res) {
        console.log("start record fail", res);
        audio["status"] = "record_fail";
        that.setState({ audio: audio });
      },
      complete: function (res) {
        audio["status"] = "record_complete";
        that.setState({ audio: audio });
        console.log("start record complete", res);
      },
    });
  };

  handlePlayRecord = () => {
    var that = this;
    console.log("start to play record");
    let { audio } = that.state;
    console.log(">>>", audio);
    this.wx.playVoice({
      localId: audio.localId,
      success: function (res) {
        console.log("start record success", res);
      },
      fail: function (res) {
        console.log("start record fail", res);
      },
      complete: function (res) {
        console.log("start record complete", res);
      },
    });
  };

  valuetext = value => {
    return value + "岁";
  };

  render() {
    const { classes } = this.props;

    const bull = <span className={classes.bullet}>•</span>;

    return (
      <Page title="">
        <SEO title="语音评测">
          <meta content="语音评测" name="description" />
        </SEO>
        <Backdrop className={classes.backdrop} open={this.state.open_backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop className={classes.backdrop} open={this.state.recording}>
          <Typography>请讲:</Typography>
        </Backdrop>
        <Card className={classes.root} variant="outlined">
          <CardContent className={classes.content}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              每日一词
            </Typography>
            <Typography variant="h5" component="h2">
              <TextField
                id="standard-full-width"
                style={{ margin: 8 }}
                placeholder="输入要评测的内容"
                value={this.state.content}
                onChange={e => {
                  this.setState({ content: e.target.value });
                }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Typography>
            {/* <Typography className={classes.pos} color="textSecondary">
              adjective
            </Typography>
            <Typography variant="body2" component="p">
              well meaning and kindly.
            </Typography> */}

            <Typography>status: {this.state.audio.status}</Typography>
            <Typography>
              总体评分:{" "}
              {
                this.state.audio.result.data.read_sentence.rec_paper
                  .read_chapter.total_score
              }
            </Typography>
            <Typography>
              完整度评分:{" "}
              {
                this.state.audio.result.data.read_sentence.rec_paper
                  .read_chapter.integrity_score
              }
            </Typography>
            <Typography>
              流利度评分:{" "}
              {
                this.state.audio.result.data.read_sentence.rec_paper
                  .read_chapter.fluency_score
              }
            </Typography>
            <Typography>
              准确度评分:{" "}
              {
                this.state.audio.result.data.read_sentence.rec_paper
                  .read_chapter.accuracy_score
              }
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleStartRecord}
            >
              开始录音
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleStopRecord}
            >
              结束录音
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handlePlayRecord}
            >
              播放录音
            </Button>
          </CardActions>
        </Card>
      </Page>
    );
  }
}

export default withRoot(withStyles(styles)(Pingce));
